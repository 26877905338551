import Mock from "../mock";

const database = {
  information: {
    name: 'Dhrumit Sheth',
    aboutContent: "As a Frontend Web Developer with a passion for leadership, I excel in delivering clean, efficient code and pixel-perfect designs. I specialize in creating scalable, secure web applications and thrive in leading teams to drive projects with precision and creativity. My commitment extends beyond coding to fostering collaborative success and continuously enhancing my leadership skills in every project I undertake.",
    age: '',
    phone: '',
    nationality: '',
    language: 'English, Hindi, Gujarati',
    email: '',
    address: 'Vancouver, BC Canada',
    socialLinks: {
      facebook: 'https://www.facebook.com/dhruvsheth7',
      twitter: '',
      pinterest: '',
      behance: '',
      instagram:'https://www.instagram.com/sheth.dhrumit/',
      linkedin: 'https://www.linkedin.com/in/dhrumit/',
      dribbble: '',
      github: 'https://github.com/immortalstar7'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/DhrumitSheth.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
    },
    {
      title: "Web Development",
      icon: 'code',
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Dhrumit is a solid engineer who is very knowledgeable front-end web developer (JavaScript, ReactJS, CSS). He is capable of working iterating on product delivery via rapid prototyping. He communicates well with key stakeholders and his management and peers alike.",
      author: {
        name: 'Jesus Cagide',
        designation: 'Director of Product Management, Stash Inc'
      }
    },
    {
      id: 2,
      content: "Dhrumit highlights his skills in problem-solving and his expertise in both front-end and back-end technologies. His experience at Ooma and his curiosity as an engineer are noted, along with his contribution to team efforts.",
      author: {
        name: 'Vishrut Sharma',
        designation: 'Senior Software Engineer, Ooma Inc'
      }
    },
    {
      id: 3,
      content: "Worked with Dhrumit for over 3 years. He was our goto man for all our front end development. He was able to manage both legacy applications and also keep an eye out for the future. Very excited about new projects and is on top of things quickly. Wish him the best.",
      author: {
        name: 'Akash Ravindranath',
        designation: 'Engineering Manager, Ooma Inc'
      }
    },
    {
      id:4,
      content:"In his role as lead front-end engineer on our team, Dhrumit not only demonstrated his ability to deliver complex projects but also showcased his willingness to mentor and elevate the skills of junior engineers. It resulted in our team consistently shipping high-quality features that met customer expectations. Dhrumit's proactive exploration of various product areas, dedication to creating reusable components, and curiosity for the business underscore his commitment to continuous learning and scaling impact. I'm confident in Dhrumit's technical skills as well as leadership potential and believe he is poised for great achievements in the future.",
      author:{
        name:"Tingting Wu",
        designation:"Senior Product Manager, ZoomInfo"
      }
    },
    {
      id:5,
      content:"I had an opportunity to work with Dhrumit while I was the Product Manager for the Admin Experience (AdminEx) team at Highspot. When I took over the AdminEx product space, Dhrumit was a critical partner to help me learn the new product space and to help me get up to speed on in-progress features. I especially appreciate Dhrumit’s communication. He made sure that all of our important stakeholders were aware of each critical decision made and when it came time to ensure the quality of the work that Dhrumit had built, he was the one who pushed the hardest on us to test and ensure that the customer experience would be great. Dhrumit is always willing to speak up and to provide his thoughts during our standups which helped foster a positive culture throughout remote work.",
      author:{
        name:"Tristan Mikoleit",
        designation:"Principal Product Manager, Highspot"
      }
    }
  ],
  skills: [
    {
      title: "Front End Development",
      value: 90
    },
    {
      title: "Javascript",
      value: 80
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 90
    },
    {
      title: "Redux",
      value: 70
    },
    {
      title: "Typescript",
      value: 65
    },
    {
      title: "MongoDB",
      value: 65
    },
    {
      title: "Jest",
      value: 70
    },
    {
      title: "TestCafe",
      value: 70
    },
    {
      title: "AWS",
      value: 70
    },
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "SASS",
      value: 90
    },
    {
      title: "STORYBOOK",
      value: 90
    },

    {
      title: "Photoshop",
      value: 65
    },
    {
      title: "Full Stack Development",
      value: 70
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2022 - Present",
        position: "Senior Software Engineer Lead",
        company: "Highspot Inc",
        details: "I lead a team of Front End Developers in enhancing the AdminEx Settings Feature module. My role involves mentoring fellow engineers, providing guidance and support to overcome their challenges and enabling them to independently lead their feature projects. I have established a standardized blueprint for feature work to promote consistency and efficiency within the team. Additionally, I am focused on developing reusable components and features specifically for administrative settings, aimed at providing company-wide benefits and improved usability.",
        location:"Vancouver, BC"
      },
      {
        id: 2,
        year: "2017 - 2022",
        position: "Frontend Developer",
        company: "Ooma Inc",
        details: "Developed and refined comprehensive UI features for the product, offering analytic insights to assist business users in enhancing their overall experience. Utilized ReactJS and Redux to overhaul end-to-end functionalities of the OOMA portal, significantly enhancing code reusability and minimizing load times.",
        location:"Sunnyvale, CA"
      },
      {
        id: 3,
        year: "2016 - 2017",
        position: "Front End Developer",
        company: "Auryc Inc (Acquired by Heap)",
        details: "Created fully functional UI features of the Product that provided Analytic information to aid Business user\n" +
            "to improve overall experience. Created features and resuable components for Auryc's session replay product using ReactJS.",
        location:"Los Altos, CA"
      },
      {
        id: 4,
        year: "2015 - 2016",
        position: "Front End Developer",
        company: "Signet Media Inc",
        details: "Developed powerful and intuitive Web Application for Digital Signage devices by interacting with clients to provide them with the desired solution",
        location:"Santa Clara, CA"
      },
      {
        id: 5,
        year: "2014 - 2014",
        position: "Software Engineering Intern",
        company: "Genapsys Inc",
        details: "Designed the Front-End Dashboard for DNA Sequencing Application using AngularJS, HTML5, Javascript.",
        location:"Redwood City, CA"
      },
      {
        id: 6,
        year: "2014 - 2014",
        position: "Software Engineering Intern",
        company: "Autodesk Inc",
        details: "Designed a database to hold profiling data about Rendering Service compute usage using Java and MySql.",
        location:"San Francisco, CA"
      },
      {
        id: 7,
        year: "2012 - 2013",
        position: "Web Developer",
        company: "Adinath Offset",
        details: "Developed frontend of their native application using HTML5, CSS and JavaScript.",
        location:" Gujarat, India"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2013 - 2015",
        graduation: "MS Software Engineering",
        university: "San Jose State University",
        details: "Pursued masters in Software Engineering with focus on Web Development and Data Science."
      },
      {
        id: 2,
        year: "2008 - 2012",
        graduation: "Bachelor of Engineering",
        university: "Gujarat Technological University",
        details: "Pursued BE in Computer Engineering with focus on engineering fundamentals."
      },
      {
        id: 3,
        year: "2006 - 2008",
        graduation: "Higher Schoold Graduation",
        university: "Dayamayi Mata Highschool",
        details: "Pursued Higher Secondary Education with focus on Science and Mathematics."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+1-541-406-0710'],
    emailAddress: ['dhruvsheth7@gmail.com'],
    address: "Vancouver, BC Canada"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});